export default {
  methods: {
    usersFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      const scan1 = item.username.toLowerCase().includes(search.toLowerCase())
      const scan2 = item.email.toLowerCase().includes(search.toLowerCase())
      return scan0 || scan1 || scan2
    },

    customersFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      const scan1 = item.username.toLowerCase().includes(search.toLowerCase())
      const scan2 = item.email.toLowerCase().includes(search.toLowerCase())
      return scan0 || scan1 || scan2
    },
    
    whitelabelsFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      const scan1 = item.companyName.toLowerCase().includes(search.toLowerCase())
      return scan0 || scan1
    }
  }
}
