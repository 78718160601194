<template>
  <div>
    <template v-for="(alert, i) in alerts">
      <v-snackbar  
        :key="`alert-${i}`"
        :value="true"
        :timeout="alert.duration"
        :top="!alert.asLogin"
        :right="!alert.asLogin"
        :bottom="alert.asLogin"
        :color="(alert.color ? alert.color : 'primary')"
        :style="alert.asLogin ? `bottom: ${10 + ((alerts.length - 1 - i) * 70)}px` : `top: ${10 + ((alerts.length - 1 - i) * 70)}px`"
        class="snackbar-transition"
      >
        <div class="d-flex justify-space-between align-center">
          <h4 class="pa-0 ma-0" style="flex: 1; font-weight: 200 !important;">{{ alert.message }}</h4>

          <v-divider vertical class="mr-5" />
          <v-btn small icon @click="removeItem(i)">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </div>
      </v-snackbar>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    alerts: []
  }),

  created () {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'toggleSnackbar') {
        let payload = mutation.payload

        if (!payload) {
          payload = {
            duration: 6000,
            message: this.$i18n.t('helpers.errors.disaster'),
            color: 'red darken-4'
          }
        }

        if (!payload.duration) {
          payload.duration = 6000
        }

        this.alerts.push(payload)

        setTimeout(() => {
          this.alerts.pop()
        }, (payload.duration | 6000))
      }
    })
  },

  methods: {
    removeItem (i) {
      this.alerts.splice(i, 1)
    }
  }
}
</script>
