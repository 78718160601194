import { Loader } from '@googlemaps/loader'

const googlemaps = {}

googlemaps.install = (Vue) => {
  Vue.prototype.$googlemaps = new Loader({
    apiKey: 'AIzaSyDm3PUrP5kSJeLEk_W3qsjmwcr5v0uBUAQ',
    version: '3',
    libraries: ['drawing', 'places'],
    language: 'en'
  })
}

export default googlemaps