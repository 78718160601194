import users from './users'
import languages from './languages'
import customers from './customers'
import whitelabels from './whitelabels'

export default {
  users,
  languages,
  customers,
  whitelabels
}