import Vue from 'vue'
import App from './App.vue'
import { i18n, vuetify, assets, router, store, cookies, apollo, googlemaps } from '@/plugins'
import DatetimePicker from '@goldenm/vuetify-datetime-picker'
import Field from 'vuetify-color-field'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import '@/assets/application.scss'

Vue.config.productionTip = false

Vue.use(assets)
Vue.use(cookies)
Vue.use(DatetimePicker)
Vue.use(googlemaps)

Vue.component('v-color-field', Field)

new Vue({
  i18n,
  vuetify,
  router,
  store,
  apolloProvider: apollo,

  computed: {
    messages () {
      return this.$i18n.messages
    },

    title () {
      if (this.whitelabel.name === undefined) {
        return this.$i18n.t('app.site')
      }

      return this.whitelabel.name
    },

    whitelabel () {
      return this.$store.state.whitelabel
    }
  },

  watch: {
    $route (newVal, oldVal) {
      document.title = `${this.$i18n.t(`pages.${newVal.name}`)} | ${this.title}`
    },

    messages (newVal, oldVal) {
      document.title = `${this.$i18n.t(`pages.${this.$route.name}`)} | ${this.title}`
    },

    whitelabel (newVal, oldVal) {
      document.title = `${this.$i18n.t(`pages.${this.$route.name}`)} | ${this.title}`
    }
  },

  beforeCreate () {
    window.addEventListener('resize', () => {
      this.$store.commit('setWidth', window.innerWidth)

      if (window.innerWidth <= 930) {
        this.$store.commit('setMini', false)
      }
    })

    document.title = this.title

    const cookies = this.$cookies.get('state')
    if (cookies !== undefined) {
      this.$store.commit('setCookies', cookies === 'false')
    }

    const dark = this.$cookies.get('dark')
    if (dark !== undefined) {
      this.$store.commit('setDark', dark === 'true')
      this.$vuetify.theme.dark = dark === 'true'
    }

    const language = this.$cookies.get('language')
    if (language !== undefined) {
      this.$store.commit('setLanguage', language)
      this.$i18n.locale = language
    } else {
      this.$store.commit('setLanguage', this.$i18n.locale)
    }
  },

  created () {
    const token = this.$cookies.get('token')

    if (token !== undefined) {
      this.$store.dispatch('users/loadEntity')
    } else {
      this.$router.push('/Login')
      this.$store.commit('setLoading', false)
    }
  },

  mounted () {
    document.title = `${this.$i18n.t(`pages.${this.$route.name}`)} | ${this.title}`
  },

  beforeDestroy () {
    window.removeEventListener('resize')
  },

  render: h => h(App)
}).$mount('#app')
