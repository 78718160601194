<template>
  <v-app :dark="isDark">
    <v-content>
      <gm-app-bar />

      <div class="px-2 py-0">
        <transition name="component-fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </v-content>

    <gm-footer is-full />
  </v-app>
</template>

<script>
import { Header, Footer } from 'Components/layout'
import { mapState } from 'vuex'

export default {
  components: {
    'gm-app-bar': Header,
    'gm-footer': Footer
  },

  computed: {
    ...mapState(['isDark'])
  },

  watch: {
    isDark (newVal, oldVal) {
      this.$vuetify.theme.dark = newVal
    }
  }
}
</script>

<style lang="scss">
</style>