<template>
  <v-dialog
    v-model="isLoading"
    persistent
    dark
    width="300"
  >
    <v-card
      color="primary"
    >
      <v-card-text class="pt-3">
        <h4 class="text-center">{{ $t('helpers.loading') }}</h4>
        
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState(['isLoading'])
}
</script>