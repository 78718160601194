<template>
  <div>
    <v-app v-if="!isMobile" style="background-color: #212121;">
      <transition name="component-fade" mode="out-in">
        <router-view />
      </transition>

      <gm-snackbar />
      <gm-loader />
      <gm-cookies />
    </v-app>

    <v-app v-else :style="`background-image: url(${$assets.background}); background-size: cover; background-position: center;`">
      <v-container fluid fill-height style="background: rgba(0,0,0,0.7)">
        <v-row align="center" justify="center">
          <v-col>
            <h1 class="text-center white--text">{{ $t('errors.unsupported.title') }}</h1>
          </v-col>
        </v-row>
      </v-container>
      <gm-footer color="primary" />
    </v-app>
  </div>
</template>

<script>
import { Loader, Snackbar, Cookies } from 'Components/utils'
import { Footer } from 'Components/layout'
import { mapGetters } from 'vuex'

export default {
  components: {
    'gm-loader': Loader,
    'gm-snackbar': Snackbar,
    'gm-cookies': Cookies,
    'gm-footer': Footer
  },

  computed: mapGetters(['isMobile'])
}
</script>

<style>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
