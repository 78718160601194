export default {
  defaultObject () {
    return {
      id: undefined,
      name: '',
      companyName: '',
      customersUrl: '',
      usersUrl: '',
      primaryColor: '',
      secondaryColor: '',
      accentColor: '',
      background: null,
      logo: null,
      customersQrCode: null,
      webApp: '',
      androidApp: '',
      iosApp: ''
    }
  }
}