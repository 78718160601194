<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
        <v-card>
          <v-card-title>
            {{ $t('users.list') }}
          </v-card-title>
          <v-card-text class="text-center">
            <span style="font-size: 4rem;">
              {{ usersItems }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
        <v-card>
          <v-card-title>
            {{ $t('courses.list') }}
          </v-card-title>
          <v-card-text class="text-center">
            <span style="font-size: 4rem;">
              {{ coursesItems }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
        <v-card>
          <v-card-title>
            {{ $t('zones.list') }}
          </v-card-title>
          <v-card-text class="text-center">
            <span style="font-size: 4rem;">
              {{ zonesItems }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3" class="pa-2">
        <v-card>
          <v-card-title>
            {{ $t('pointsOfInterest.list') }}
          </v-card-title>
          <v-card-text class="text-center">
            <span style="font-size: 4rem;">
              {{ pointsOfInterestItems }}
            </span>
          </v-card-text>
        </v-card>
      </v-col> -->

      <!-- <v-col cols="12" sm="6" md="4" class="pa-2">
        <v-card>
          <v-card-title>{{ $t('home.admin.customers.title') }}</v-card-title>
          <v-card-text class="text-center pa-4 pb-8 d-flex justify-center align-center">
            <h3 style="font-size: 4em;">{{ customers }}</h3>
            <small style="font-size: 20px; margin-left: 5px;">{{ $tc('home.admin.customers.unit', customers) }}</small>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="4" class="pa-2">
        <v-card>
          <v-card-title>{{ $t('home.admin.whitelabels.title') }}</v-card-title>
          <v-card-text class="text-center pa-4 pb-8 d-flex justify-center align-center">
            <h3 style="font-size: 4em;">{{ whitelabels }}</h3>
            <small style="font-size: 20px; margin-left: 5px;">{{ $tc('home.admin.whitelabels.unit', whitelabels) }}</small>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['isDark', 'users', 'courses', 'zones', 'pointsOfInterest']),

    usersItems () {
      if (this.users.entity.__typename !== 'Administrator') {
        return this.users.list.filter((user) => user.id !== this.users.entity.id).length
      }
      return this.users.list.length
    },

    coursesItems () {
      return 0
    },

    zonesItems () {
      return 0
    },

    pointsOfInterestItems () {
      return 0
    }
  }
}
</script>