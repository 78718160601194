<template>
  <v-container fluid>
    <v-layout row wrap class="px-8">
      <h1>{{ $t("pages.profile") }}</h1>
      <v-flex xs12>
        <v-divider width="600" class="my-2" />
      </v-flex>

      <v-flex xs12 class="pa-2">
        <v-card>
          <v-card-text>
            <v-layout row wrap class="px-4">
              <v-flex xs12 class="px-2 mb-5">
                <h4>{{ $t("profile.categories.basic") }}</h4>
                <v-divider width="400" />
              </v-flex>
              <v-flex xs12 sm6 md4 class="px-2">
                <v-text-field
                  v-model="object.name"
                  :label="$t('profile.fields.name')"
                  :placeholder="$t('profile.hints.name')"
                  :color="color"
                  :errors="errors.name.length > 0"
                  :error-messages="errors.name"
                  outlined
                />
              </v-flex>

              <v-flex xs12 sm6 md4 class="px-2">
                <v-text-field
                  v-model="object.lastName"
                  :label="$t('profile.fields.lastName')"
                  :placeholder="$t('profile.hints.lastName')"
                  :color="color"
                  :errors="errors.lastName.length > 0"
                  :error-messages="errors.lastName"
                  outlined
                />
              </v-flex>

              <v-flex xs12 md4 class="px-2">
                <v-text-field
                  v-model="object.email"
                  :label="$t('profile.fields.email')"
                  :placeholder="$t('profile.hints.email')"
                  :color="color"
                  :errors="errors.email.length > 0"
                  :error-messages="errors.email"
                  outlined
                />
              </v-flex>

              <v-flex xs12 class="px-2 mb-5">
                <h4>{{ $t("profile.categories.auth") }}</h4>
                <v-divider width="400" />
              </v-flex>
              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  v-model="object.username"
                  :label="$t('profile.fields.username')"
                  :placeholder="$t('profile.hints.username')"
                  :color="color"
                  :errors="errors.username.length > 0"
                  :error-messages="errors.username"
                  outlined
                />
              </v-flex>

              <v-flex xs12 sm6 class="px-2">
                <v-text-field
                  v-model="object.password"
                  :type="showPassword ? 'text' : 'password'"
                  :label="$t('profile.fields.password')"
                  :placeholder="$t('profile.hints.password')"
                  :color="color"
                  :errors="errors.password.length > 0"
                  :error-messages="errors.password"
                  :suffix-inner="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  outlined
                />
              </v-flex>

              <v-flex xs12 class="px-2 mb-5">
                <h4>{{ $t("profile.categories.avatar") }}</h4>
                <v-divider width="400" />
              </v-flex>

              <transition name="component-fade" mode="out-in">
                <v-flex v-if="!object.avatar" class="px-2" xs12>
                  <v-file-input
                    ref="avatarField"
                    :label="$t('profile.fields.avatar')"
                    accept="image/png, image/jpg"
                    :color="color"
                    :loading="isFileUploading"
                    :error="errors.avatar.length > 0"
                    :error-messages="errors.avatar"
                    outlined
                    @change="handleAvatar"
                  />
                </v-flex>

                <v-flex v-else class="px-2" xs12>
                  <v-avatar size="200" class="elevation-1">
                    <v-img :src="object.avatar + `?random=${randomNumber}`" contain />
                  </v-avatar>
                  <br>
                  <v-btn text color="red darken-2" @click="object.avatar = null">
                    {{ $t('profile.clear') }}
                  </v-btn>
                </v-flex>
              </transition>

              <v-flex xs12 class="px-2 my-5">
                <v-divider width="400" />
              </v-flex>

              <v-flex xs12 class="px-2 d-flex justify-end">
                <v-btn :disabled="isFileUploading || isLoading" depressed color="primary" @click="submit">
                  {{ $t('profile.save') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'
import gql from 'graphql-tag'

export default {
  data () {
    return {
      object: {},
      rawErrors: {},
      showPassword: false,
      isFileUploading: false,
      isLoading: false,
      randomNumber: 0
    }
  },

  computed: {
    ...mapState(['users']),
    ...mapGetters(['color']),

    defaultObject () {
      if (this.users.entity.loaded) {
        return {
          id: this.users.entity.id,
          name: this.users.entity.name,
          lastName: this.users.entity.lastName,
          email: this.users.entity.email,
          username: this.users.entity.username,
          avatar: this.users.entity.avatar,
          password: ''
        }
      }
      return {
        id: null,
        name: 'Kenny',
        lastName: 'Mochizuki',
        token: '',
        email: '',
        password: '',
        avatar: null
      }
    },

    errors () {
      const errors = {}

      for (const key in this.defaultObject) {
        let keyCamel = key
        if (key.includes(' ')) {
          keyCamel = this.convertToCamelCase(key)
        }

        if (this.rawErrors[key] === undefined) {
          errors[keyCamel] = ''
        } else {
          errors[keyCamel] = this.rawErrors[key].join(', ')
        }
      }

      return errors
    }
  },

  watch: {
    defaultObject (newVal, oldVal) {
      this.object = Object.assign({ password: '' }, newVal)
    }
  },

  beforeMount () {
    this.object = Object.assign({ password: '' }, this.defaultObject)
  },

  methods: {
    convertToCamelCase (str) {
      str = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, (match) => {
        return match.charAt(match.length - 1).toUpperCase()
      })
      return str.charAt(0).toLowerCase() + str.substring(1)
    },

    handleAvatar (event) {
      this.uploadFile(event)
    },

    uploadFile (file) {
      if (file === undefined || file === null || this.isFileUploading) {
        return
      }

      this.isFileUploading = true

      const formData = new FormData()
      formData.append('image', file)
      formData.append('cname', 'alpha.goffice.io')
      formData.append('type', 'avatar')
      formData.append('name', this.object.username)

      let host = 'http://localhost:8000'

      if (process.env.NODE_ENV === 'production') {
        host = 'https://alpha.goffice.io'
      }
 
      axios.post(`${host}/api/v1/file/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        const { executed, result } = response.data

        if (executed) {
          this.randomNumber = parseInt(Math.random() * 1000000)
          this.object.avatar = result
        } else {
          this.$store.commit('toggleSnackbar')

          this.$refs.avatarField.reset()
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar')
        this.$refs.avatarField.reset()
      }).finally(() => {
        this.isFileUploading = false
      })
    },

    submit () {
      this.isLoading = true
      this.rawErrors = {}
      this.$apollo.mutate({
        mutation: gql`
          mutation ($data: ProfileInput!, $token: String!, $language: Languages) {
            editProfile(token: $token, language: $language, data: $data) {
              status
              errors
              result {
                ... on User {
                  token
                }

                ... on Customer {
                  token
                }

                ... on Operator {
                  token
                }

                ... on Transport {
                  token
                }

                ... on RepcomUser {
                  token
                }
              }
            }
          }
        `,
        variables: {
          data: this.object,
          language: this.$language,
          token: this.users.entity.token
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        this.isLoading = false
        const { status, errors, result } = response.data.editProfile
        const rawErrors = {}

        switch (status) {
          case 'OK':
            this.object = Object.assign({ password: '' }, this.defaultObject)
            this.$cookies.set('token', result.token)
            this.$store.dispatch('users/loadEntity', true)
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('profile.success'),
              color: 'green darken-2',
              duration: 10000
            })
            this.$router.push('/Home')
            break
          case 'UNPROCESSABLE':
            this.$store.commit('toggleSnackbar', {
              mesasge: this.$i18n.t('errors.invalidFields'),
              color: 'orange darken-2'
            })

            for (const key in errors) {
              rawErrors[this.convertToCamelCase(key)] = errors[key]
            }
            this.rawErrors = rawErrors
            break
          default:
            this.$store.commit('toggleSnackbar')
            break
        }
      }).catch((err) => {
        this.isLoading = false
        console.log(err)
        this.$store.commit('toggleSnackbar')
      })
    }
  }
}
</script>
