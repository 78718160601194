<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="500"
    max-width="90vw"
  >
    <v-card>
      <v-card-title class="d-flex align-start justify-space-between">
        <p>{{ title }}</p>
        <v-btn icon @click="discardItem()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="better-scrollbar">
        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="12" sm="6" class="pa-2">
              <v-text-field
                v-model="object.name"
                :label="$t('whitelabels.name')"
                :disabled="!isEditting"
                outlined
                :error="hasErrors('name')"
                :error-messages="getErrors('name')"
              />
            </v-col>
            <v-col cols="12" sm="6" class="pa-2">
              <v-text-field
                v-model="object.companyName"
                :label="$t('whitelabels.companyName')"
                :disabled="!isEditting"
                outlined
                :error="hasErrors('companyName')"
                :error-messages="getErrors('companyName')"
              />
            </v-col>
            <v-col cols="12" sm="6" class="pa-2">
              <v-text-field
                v-model="object.customersUrl"
                :label="$t('whitelabels.customersUrl')"
                :disabled="!isEditting"
                outlined
                :error="hasErrors('customersUrl')"
                :error-messages="getErrors('customersUrl')"
              />
            </v-col>
            <v-col cols="12" sm="6" class="pa-2">
              <v-text-field
                v-model="object.usersUrl"
                :label="$t('whitelabels.usersUrl')"
                :disabled="!isEditting"
                outlined
                :error="hasErrors('usersUrl')"
                :error-messages="getErrors('usersUrl')"
              />
            </v-col>
            <v-col cols="12" sm="6" class="pa-2">
              <v-text-field
                v-model="object.iosApp"
                :label="$t('whitelabels.iosApp')"
                :disabled="!isEditting"
                outlined
                :error="hasErrors('iosApp')"
                :error-messages="getErrors('iosApp')"
              />
            </v-col>
            <v-col cols="12" sm="6" class="pa-2">
              <v-text-field
                v-model="object.androidApp"
                :label="$t('whitelabels.androidApp')"
                :disabled="!isEditting"
                outlined
                :error="hasErrors('androidApp')"
                :error-messages="getErrors('androidApp')"
              />
            </v-col>
            <v-col cols="12" class="pa-2">
              <v-text-field
                v-model="object.webApp"
                :label="$t('whitelabels.webApp')"
                :disabled="!isEditting"
                outlined
                :error="hasErrors('webApp')"
                :error-messages="getErrors('webApp')"
              />
            </v-col>
            <v-col cols="12" sm="4" class="pa-2">
              <v-color-field
                v-model="object.primaryColor"
                :disabled="!isEditting"
                :error="hasErrors('primaryColor')"
                :error-messages="getErrorsArray('primaryColor')"
                :label="$t('whitelabels.primaryColor')"
                outlined
                readonly
              />
            </v-col>
            <v-col cols="12" sm="4" class="pa-2">
              <v-color-field
                v-model="object.secondaryColor"
                :disabled="!isEditting"
                :error="hasErrors('secondaryColor')"
                :error-messages="getErrorsArray('secondaryColor')"
                :label="$t('whitelabels.secondaryColor')"
                outlined
                readonly
              />
            </v-col>
            <v-col cols="12" sm="4" class="pa-2">
              <v-color-field
                v-model="object.accentColor"
                :disabled="!isEditting"
                :error="hasErrors('accentColor')"
                :error-messages="getErrorsArray('accentColor')"
                :label="$t('whitelabels.accentColor')"
                outlined
                readonly
              />
            </v-col>
            <v-col cols="12" class="pa-2">
              <v-row no-gutters>
                <v-col cols="9" class="pa-2">
                  <v-file-input
                    v-model="backgroundFile"
                    outlined
                    :disabled="!isEditting"
                    :label="$t('whitelabels.background')"
                    counter
                    prepend-icon="mdi-paperclip"
                    :show-size="1000"
                    :error="hasErrors('background')"
                    :error-messages="getErrors('background')"
                    @change="toBase64($event, 'background')"
                  >
                    <template #selection="{ text }">
                      <v-chip
                        color="secondary black--text"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="3" class="pa-2 d-flex justify-end">
                  <v-card v-if="backgroundFile || object.background" width="100%" outlined>
                    <v-img
                      contain
                      aspect-ratio="1"
                      :src="object.background"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pa-2">
              <v-row no-gutters>
                <v-col cols="9" class="pa-2">
                  <v-file-input
                    v-model="logoFile"
                    outlined
                    :disabled="!isEditting"
                    :label="$t('whitelabels.logo')"
                    counter
                    prepend-icon="mdi-paperclip"
                    :show-size="1000"
                    :error="hasErrors('logo')"
                    :error-messages="getErrors('logo')"
                    @change="toBase64($event, 'logo')"
                  >
                    <template #selection="{ text }">
                      <v-chip
                        color="secondary black--text"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="3" class="pa-2 d-flex justify-end">
                  <v-card v-if="logoFile || object.logo" width="100%" outlined>
                    <v-img
                      contain
                      aspect-ratio="1"
                      :src="object.logo"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          v-if="isEditting"
          :ripple="false"
          outlined
          dark
          color="primary"
          @click="discardItem"
        >
          {{ $t('actions.discard') }}
        </v-btn>
        <v-btn
          v-if="isEditting"
          :ripple="false"
          depressed
          color="primary"
          @click="saveItem(object.id)"
        >
          {{ $t('actions.save') }}
        </v-btn>
        <v-btn
          v-if="!isEditting"
          :ripple="false"
          depressed
          color="orange darken-1 white--text"
          @click="isEditting = true"
        >
          {{ $t('actions.edit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { orm } from '@/mixins'
import addMutation from '@/graphql/mutations/whitelabels/add.gql'
import editMutation from '@/graphql/mutations/whitelabels/edit.gql'

export default {

  mixins: [orm],
  model: {
    prop: 'data',
    event: 'set-object'
  },

  props: {
    showDialog: { type: Boolean, default: () => false },
    data: { type: Object, default: () => {} },
    readonly: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      show: false,
      errors: {},
      backgroundFile: null,
      logoFile: null
    }
  },

  computed: {
    ...mapState(['isDark', 'customers', 'whitelabels', 'users']),
    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('toggle-object', value)
      }
    },

    dialog: {
      get () {
        return this.showDialog
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    },

    isEditting: {
      get () {
        return !this.readonly
      },

      set (value) {
        this.$emit('set-readonly', value)
      }
    },

    title () {
      return this.object.id === undefined ? this.$i18n.t('whitelabels.new') : this.$i18n.t('whitelabels.edit')
    }
  },

  watch: {},

  created () {
    console.log('hola')
  },

  methods: {
    async toBase64 (file, property) {
      console.log(file, property)
      if (!file) {
        this.object[property] = ''
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const result = reader.result

        if (
          result.startsWith('data:image/png') ||
          result.startsWith('data:image/jpeg') ||
          result.startsWith('data:image/svg+xml') ||
          result.startsWith('data:image/webp') ||
          result.startsWith('data:image/gif')
        ) {
          this.object[property] = result
        } else {
          this.$store.commit('toggleSnackbar', {
            message: this.$t('helpers.errors.invalidFormat'),
            color: 'orange darken-2'
          })
        }
      }
    },

    async saveItem (id) {
      this.isLoading = true
      let background = null
      let logo = null
      if (this.backgroundFile) {
        background = this.object.background
      }
      if (this.logoFile) {
        logo = this.object.logo
      }
      const data = {
        id: id,
        name: this.object.name,
        companyName: this.object.companyName,
        customersUrl: this.object.customersUrl,
        usersUrl: this.object.usersUrl,
        primaryColor: this.object.primaryColor,
        secondaryColor: this.object.secondaryColor,
        accentColor: this.object.accentColor,
        background,
        logo,
        webApp: this.object.webApp,
        androidApp: this.object.androidApp,
        iosApp: this.object.iosApp
      }
      if (id) {
        await this.edit(data)
      } else {
        await this.add(data)
      }
    },

    evaluateResponse ({ status, errors, result, action }) {
      this.isLoading = false
      console.log(status, errors)
      switch (status) {
        case 'OK':
          this.discardItem()
          this.resetErrors()
          this.$store.commit('toggleSnackbar', {
            color: 'green darken-2',
            message: this.$t('helpers.saved.successfully')
          })
          if (action === 'add' || action === 'edit') {
            this.$store.dispatch('whitelabels/getList', true)
          }
          break
        case 'UNPROCESSABLE':
          this.errors = this.parseErrors(errors)
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'BADREQUEST':
          this.errors = this.parseErrors(errors)
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'LIMITREACHED':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.limit_reached')
          })
          break
      }
    },

    add (data) {
      const action = 'add'
      this.$apollo.mutate({
        mutation: addMutation,
        variables: {
          token: this.users.entity.token,
          data
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.addWhitelabel
        this.evaluateResponse({ status, errors, result, action })
      }).catch(err => {
        console.log('[Devices Dialog] Error handled', err)
        this.$store.commit('toggleSnackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },

    edit (data) {
      const action = 'edit'
      this.$apollo.mutate({
        mutation: editMutation,
        variables: {
          token: this.users.entity.token,
          data
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.editWhitelabel
        this.evaluateResponse({ status, errors, result, action })
      }).catch(err => {
        console.log('[Devices Dialog] Error handled', err)
        this.$store.commit('toggleSnackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },

    discardItem () {
      this.dialog = false
      this.object = Object.assign({}, this.$store.getters['whitelabels/defaultObject'])
      this.resetErrors()
    }
  }
}
</script>
