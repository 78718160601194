// Main imports
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from '../locales/en.yml'
import es from '../locales/es.yml'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'es',
  messages: {
    ...en,
    ...es
  },
  silentTranslationWarn: true
})
