import _ from 'lodash'

export default {
  methods: {
    resetErrors () {
      this.errors = {}
    },

    parseErrors (errors) {
      return errors
    },

    hasErrors (field) {
      const errs = _.get(this.errors, field)
      if (errs) {
        return true
      }
      return false
    },

    hasContainerErrors (path) {
      return Object.keys(this.errors).filter((item) => {
        return item.startsWith(path)
      }).length > 0
    },

    getContainerErrors (path) {
      const parsedErrors = []
      const paths = Object.keys(this.errors).filter((item) => {
        return item.startsWith(path)
      })

      for (const path of paths) {
        const errs = this.getErrors(path)

        if (errs) {
          parsedErrors.push(errs)
        }
      }

      return parsedErrors.join(', ')
    },

    getErrors (field) {
      return this.getErrorsArray(field).join(', ')
    },

    getErrorsArray (field) {
      const errs = _.get(this.errors, field)

      if (errs) {
        const parsedErrors = []

        for (const i in errs) {
          if (['minLength', 'maxLength'].includes(_.get(errs[i], 'code'))) {
            parsedErrors.push(this.$t(`errors.${errs[i].code}`, { ...errs[i] }))
          } else {
            parsedErrors.push(this.$t(`errors.${errs[i].code}`))
          }
        }

        return parsedErrors
      }
      return []
    }
  }
}