<template>
  <v-flex class="d-flex justify-center align-center">
    <v-layout row wrap justify-center align-center class="pa-4">
      <v-flex xs12 md6 class="px-2">
        <v-card class="pb-5" light>
          <v-card-title class="text-center d-flex" style="flex-direction: column;">
            <p class="pb-0 mb-0">{{ $t('recovery.title') }}</p>
          </v-card-title>

          <v-card-text>
            <v-stepper v-model="currentTab" class="elevation-0" style="box-shadow: none !important;">
              <v-stepper-header>
                <v-stepper-step :complete="currentTab > 0" :step="0">{{ $t('recovery.findAccount.tab') }}</v-stepper-step>
                <v-divider />
                <v-stepper-step :complete="currentTab > 1" :step="1">{{ $t('recovery.otp.tab') }}</v-stepper-step>
                <v-divider />
                <v-stepper-step :complete="currentTab > 2" :step="2">{{ $t('recovery.recoveryPassword.tab') }}</v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content :step="0">
                  <p class="mt-5">{{ $t('recovery.findAccount.title') }}</p>
                  <v-flex xs12>
                    <v-text-field
                      v-model="email"
                      :label="$t('users.email')"
                      outlined
                      hide-details
                    />
                  </v-flex>
                </v-stepper-content>

                <v-stepper-content :step="1">
                  <p class="mt-5">{{ $t('recovery.otp.title') }}</p>
                  <v-flex xs12>
                    <v-text-field
                      v-model="otp"
                      :label="$t('recovery.otp.field')"
                      outlined
                      hide-details
                    />
                  </v-flex>
                </v-stepper-content>

                <v-stepper-content :step="2">
                  <p class="mt-5">{{ $t('recovery.recoveryPassword.title') }}</p>
                  <v-flex xs12 class="my-2">
                    <v-text-field
                      v-model="newPassword"
                      :label="$t('recovery.password.new')"
                      outlined
                      hide-details
                    />
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      v-model="passwordConfirmation"
                      :label="$t('recovery.password.confirmation')"
                      outlined
                      hide-details
                    />
                  </v-flex>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>

          <v-card-actions class="d-flex px-6 justify-end">
            <v-btn
              v-if="currentTab === 0"
              depressed
              :disabled="email.length === 0 || isLoading"
              color="primary"
              @click="findAccount"
            >
              {{ $t('recovery.find') }}
            </v-btn>

            <v-btn
              v-if="currentTab === 1"
              depressed
              :disabled="otp.length === 0 || isLoading"
              color="primary"
              @click="validateOtp"
            >
              {{ $t('recovery.validate') }}
            </v-btn>

            <v-btn
              v-if="currentTab === 2"
              depressed
              :disabled="newPassword.length === 0 || passwordConfirmation.length === 0 || newPassword !== passwordConfirmation || isLoading"
              color="primary"
              @click="changePassword"
            >
              {{ $t('recovery.change') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import gql from 'graphql-tag'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      isLoading: false,
      currentTab: 0,
      email: '',
      otp: '',
      newPassword: '',
      passwordConfirmation: ''
    }
  },

  computed: {
    ...mapState(['width', 'isDark']),

    color () {
      if (this.isDark) {
        return 'white black--text'
      }

      return 'primary white--text'
    }
  },

  mounted () {
    this.$store.commit('setLoading', false)
  },

  methods: {
    findAccount () {
      this.isLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation ($email: String!) {
            findAccount(email: $email) {
              status
              errors
            }
          }
        `,
        variables: {
          email: this.email
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status } = response.data.findAccount

        this.isLoading = false
        switch (status) {
          case 'OK':
            this.currentTab = 1
            this.email = ''
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('recovery.success.otp'),
              color: 'green darken-2'
            })
            break
          case 'NOT_FOUND':
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('recovery.errors.email'),
              color: 'orange darken-2'
            })
            break
          case 'UNAUTHORIZED':
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('errors.accessDenied'),
              color: 'orange darken-2'
            })
            break
          case 'INTERNALERROR':
            this.$store.commit('toggleSnackbar')
            break
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar')
      }).finally(() => {
        this.isLoading = false
      })
    },
    validateOtp () {
      this.isLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation ($code: String!) {
            validateOtp(code: $code) {
              status
              errors
            }
          }
        `,
        variables: {
          code: this.otp
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status } = response.data.validateOtp

        this.isLoading = false
        switch (status) {
          case 'OK':
            this.currentTab = 2
            break
          case 'NOT_FOUND':
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('recovery.errors.otp'),
              color: 'orange darken-2'
            })
            break
          case 'INTERNALERROR':
            this.$store.commit('toggleSnackbar')
            break
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar')
      }).finally(() => {
        this.isLoading = false
      })
    },
    changePassword () {
      this.isLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation ($password: String!, $passwordConfirmation: String!, $code: String!) {
            recoveryPassword(password: $password, passwordConfirmation: $passwordConfirmation, code: $code) {
              status
              errors
            }
          }
        `,
        variables: {
          password: this.newPassword,
          passwordConfirmation: this.passwordConfirmation,
          code: this.otp
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status } = response.data.recoveryPassword

        this.isLoading = false
        switch (status) {
          case 'OK':
            this.currentTab = 0
            this.otp = ''
            this.newPassword = ''
            this.passwordConfirmation = ''
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('recovery.success.changed'),
              color: 'green darken-2'
            })
            this.$router.push('/Login')
            break
          case 'UNPROCESSABLE':
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('recovery.errors.password'),
              color: 'orange darken-2'
            })
            break
          case 'NOT_FOUND':
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('recovery.errors.otp'),
              color: 'orange darken-2'
            })
            break
          case 'INTERNALERROR':
            this.$store.commit('toggleSnackbar')
            break
        }
      }).catch((error) => {
        this.$store.commit('toggleSnackbar')
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>