<template>
  <v-simple-table class="transparent">
    <tbody class="white--text">
      <tr>
        <td class="d-flex justify-center align-center" colspan="5">
          <v-avatar :size="40">
            <v-img :src="unit.icon.uri || '/car/render'" contain />
          </v-avatar>

          <h3 class="ml-3">{{ unit.name }}</h3>
        </td>
      </tr>

      <tr>
        <td class="text-right font-weight-bold">{{ $t('units.ident') }}</td>
        <td class="text-left">{{ unit.ident }}</td>

        <td><v-divider vertical /></td>

        <td class="text-right font-weight-bold">{{ $t('units.phone') }}</td>
        <td class="text-left">{{ unit.phone }}</td>
      </tr>

      <tr>
        <td class="text-right font-weight-bold">{{ $t('units.message.battery.voltage') }}</td>
        <td class="text-left">{{ message === undefined ? 'NA' : `${message.battery.voltage} V` }}</td>

        <td><v-divider vertical /></td>

        <td class="text-right font-weight-bold">{{ $t('units.message.battery.charging') }}</td>
        <td class="text-left">
          <v-chip small :color="(message === undefined ? false : message.battery.charging.status) ? 'success' : 'error'">{{ $t(`states.charging.${message === undefined ? false : message.battery.charging.status}`) }}</v-chip>
        </td>
      </tr>

      <tr>
        <td class="text-right font-weight-bold">{{ $t('units.message.source.voltage') }}</td>
        <td class="text-left">{{ message === undefined ? 'NA' : `${message.source.voltage} V` }}</td>

        <td><v-divider vertical /></td>

        <td class="text-right font-weight-bold">{{ $t('units.message.source.charging') }}</td>
        <td class="text-left">
          <v-chip small :color="(message === undefined ? false : message.source.charging.status) ? 'success' : 'error'">{{ $t(`states.connection.${message === undefined ? false : message.source.charging.status}`) }}</v-chip>
        </td>
      </tr>

      <tr>
        <td class="text-right font-weight-bold">{{ $t('units.message.motion.state') }}</td>
        <td class="text-left">
          <v-chip small :color="(message === undefined ? false : message.position.motion.state) ? 'success' : 'error'">{{ $t(`states.movement.${message === undefined ? false : message.position.motion.state}`) }}</v-chip>
        </td>

        <td><v-divider vertical /></td>

        <td class="text-right font-weight-bold">{{ $t('units.message.motion.value') }}</td>
        <td class="text-left">{{ message === undefined ? 'NA' : `${message.position.motion.value}` }}</td>
      </tr>

      <tr>
        <td class="text-right font-weight-bold">{{ $t('units.message.gprs.baseId') }}</td>
        <td class="text-left">{{ message === undefined ? 'NA' : `${message.gprs.baseId}` }}</td>

        <td><v-divider vertical /></td>

        <td class="text-right font-weight-bold">{{ $t('units.message.gprs.networkCode') }}</td>
        <td class="text-left">{{ message === undefined ? 'NA' : `${message.gprs.networkCode}` }}</td>
      </tr>

      <tr>
        <td class="text-right font-weight-bold">{{ $t('units.message.gprs.locationCode') }}</td>
        <td class="text-left">{{ message === undefined ? 'NA' : `${message.gprs.locationCode}` }}</td>

        <td><v-divider vertical /></td>

        <td class="text-right font-weight-bold">{{ $t('units.protocolId') }}</td>
        <td class="text-left">{{ unit.protocol.name }}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    unit: {
      type: Object,
      required: true
    },

    message: {
      type: Object,
      required: false,
      default: () => undefined
    }
  }
}
</script>