import LoginLayout from '@/layouts/Login.vue'
import AppLayout from '@/layouts/Main.vue'

import NotFound from '@/views/NotFound.vue'
import Login from '@/views/Login.vue'
import Recovery from '@/views/Recovery.vue'
import Home from '@/views/Home.vue'
import Profile from '@/views/Profile.vue'
import Users from '@/views/Users.vue'
import Customers from '@/views/Customers.vue'
import Whitelabels from '@/views/Whitelabels.vue'

export default [
  {
    path: '/',
    redirect: '/Login'
  },
  {
    path: '/',
    component: LoginLayout,
    children: [
      {
        path: 'Login',
        name: 'login',
        components: { default: Login }
      },
      {
        path: 'Recovery',
        name: 'recovery',
        components: { default: Recovery }
      }
    ]
  },
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: 'Home',
        name: 'home',
        components: { default: Home }
      },
      {
        path: 'Profile',
        name: 'profile',
        components: { default: Profile }
      },
      {
        path: 'Users',
        name: 'users',
        components: { default: Users }
      },
      {
        path: 'Customers',
        name: 'customers',
        components: { default: Customers }
      },
      {
        path: 'Whitelabels',
        name: 'whitelabels',
        components: { default: Whitelabels }
      }
    ]
  },
  {
    path: '*',
    component: NotFound
  }
]